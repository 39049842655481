import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class OurDirectors extends React.Component {
	render() {
		const siteTitle = 'Our Directors'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Our Directors</h3>
								<div className="directorsinner">
									<div className="row">
										<div className="col-md-6 col-sm-6 col-xs-12">
											<div className="eq-ui-card eq-ui-card-image">
												<div className="eq-ui-card-title eq-ui-card-title-expand"></div>
												<div className="eq-ui-card-actions">
													<span className="shades-text-white">
														David Shaw (CEO)
													</span>
												</div>
											</div>
											<div className="detdirct">
												<p>
													David Shaw is the CEO of WSC Group. Under David’s
													directorship, WSC Group is focused...
												</p>
												<a
													href="/about-us/david-shaw-ceo"
													className="btn btn-primary eq-ui-waves-light drctbtn waves-effect waves-effect waves-light"
												>
													READ MORE
												</a>
											</div>
										</div>
										<div className="col-md-6 col-sm-6 col-xs-12">
											<div className="eq-ui-card eq-ui-card-image">
												<div className="eq-ui-card-title eq-ui-card-title-expand"></div>
												<div className="eq-ui-card-actions">
													<span className="shades-text-white">
														Catherine Simons
													</span>
												</div>
											</div>
											<div className="detdirct">
												<p>
													Catherine Simons specialises in rental property
													investment and small to medium..
												</p>
												<a
													href="/about-us/catherine-simons"
													className="btn btn-primary eq-ui-waves-light drctbtn waves-effect waves-effect waves-light"
												>
													READ MORE
												</a>
											</div>
										</div>
										<div className="col-md-6 col-sm-6 col-xs-12">
											<div className="eq-ui-card eq-ui-card-image">
												<div className="eq-ui-card-title eq-ui-card-title-expand"></div>
												<div className="eq-ui-card-actions">
													<span className="shades-text-white">Sarah Cox</span>
												</div>
											</div>
											<div className="detdirct">
												<p>
													Sarah Cox is the National Administration Director
													who's role is to deliver...
												</p>
												<a
													href="/about-us/sarah-cox"
													className="btn btn-primary eq-ui-waves-light drctbtn waves-effect waves-effect waves-light"
												>
													READ MORE
												</a>
											</div>
										</div>
										<div className="col-md-6 col-sm-6 col-xs-12">
											<div className="eq-ui-card eq-ui-card-image">
												<div className="eq-ui-card-title eq-ui-card-title-expand"></div>
												<div className="eq-ui-card-actions">
													<span className="shades-text-white">Todd Hearne</span>
												</div>
											</div>
											<div className="detdirct">
												<p>
													Todd Hearne oversees our Newcastle operations. Having
													18 years’ experience...
												</p>
												<a
													href="/about-us/todd-hearne"
													className="btn btn-primary eq-ui-waves-light drctbtn waves-effect waves-effect waves-light"
												>
													READ MORE
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default OurDirectors

export const OurDirectorsPageQuery = graphql`
	query OurDirectorsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
